import React from "react";

const LoginFooter = () => {
  return (
    <div className="border-t-2 border-t-primary pt-3 flex flex-row items-center justify-center gap-4 my-20">
      <a
        href="https://www.iombank.com/global/terms-conditions.html#olb"
        className="text-secondary text-[11px] underline"
      >
        Legal Info
      </a>
      <p className="text-[8px]">•</p>
      <a
        href="https://www.iombank.com/iom/global/security.ashx"
        className="text-secondary text-[11px] underline"
      >
        Security Center
      </a>
      <p className="text-[8px]">•</p>
      <a
        href="https://www.iombank.com/cookiesprivacy"
        className="text-secondary text-[11px] underline"
      >
        Privacy & Cookies
      </a>
      <p className="text-[8px]">•</p>
      <a
        href="https://www.iombank.com/iom/global/accessibility.ashx"
        className="text-secondary text-[11px] underline"
      >
        Accessibility
      </a>
      <p className="text-[8px]">•</p>
      <p className="text-primary text-[11px]">
        © 2005-{new Date().getFullYear()} The Royal Bank of Scotland
        International Limited
      </p>
    </div>
  );
};

export default LoginFooter;
