// export const getBaseUrl = () =>
//   process.env.NODE_ENV === "development"
//     ? "http://localhost:8888/iomassetmanagement.com/web_api/"
//     : "https://iomassetmanagement.com/web_api/";
export const getBaseUrl = () => "https://iomassetmanagement.com/web_api/";

export const numberWithCommas = (x: number, decimals?: number) => {
  return x.toFixed(decimals || 0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const currencies = [
  {
    title: "GBP",
    symbol: "£",
  },
  {
    title: "USD",
    symbol: "$",
  },
  {
    title: "EUR",
    symbol: "€",
  },
];
